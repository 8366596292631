<template>
	<modal
		name="counter-approved"
		:width="500"
		:height="'auto'"
		:adaptive="true"
		:clickToClose="false"
		:scrollable="true"
		@before-open="beforeOpen"
	>
		<div>
			<template>
				<div
					class="modal-approve"
					v-if="checkState.successed"
					:class="theme === 'dark-theme' && 'modal-approve_dark'"
				>
					<div class="modal-approve__text-container">
						<h3
							class="modal-approve__title"
							:class="theme === 'dark-theme' && 'modal-approve__title_dark'"
						>
							Вам доступен
							<span class="modal-approve__title_span">(1)</span> подарок
						</h3>
						<p
							class="modal-approve__subtitle"
							:class="theme === 'dark-theme' && 'modal-approve__subtitle_dark'"
						>
							За передачу показаний
						</p>
					</div>

					<img
						src="@/img/present.png"
						class="modal-approve__image"
						alt="Подарок"
					/>

					<div class="modal-approve__buttons-container">
						<a
							class="modal-approve__first-button"
							:class="
								theme === 'dark-theme' && 'modal-approve__first-button_dark'
							"
							href="https://квартплата.онлайн/gift?bid=11263"
							target="_blank"
						>
							Выбрать
						</a>
						<button
							class="modal-approve__second-button"
							@click="modalClose"
							:class="
								theme === 'dark-theme' && 'modal-approve__second-button_dark'
							"
						>
							Отказаться
						</button>
					</div>
				</div>
			</template>
		</div>
	</modal>
</template>

<script>
export default {
	data() {
		return {
			params: {
				orderId: ""
			},
			url: process.env.VUE_APP_URL,
			loading: true,
			checkState: {
				orderId: "",
				successed: true,
				message: ""
			},
			theme: ""
		};
	},
	computed: {},
	methods: {
		async modalClose() {
			this.$modal.hide("counter-approved");
		},
		async beforeOpen() {
			this.theme = this.$useTheme();
		}
	}
};
</script>

<style scoped>
.modal-approve {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: #F1F2FF;
	padding: 48px 38px 38px 38px;
	box-shadow: 1px 1px 6px 0 #0000000f;
	border-radius: 24px;
}

.modal-approve_dark {
	background-color: #252525;
}

.modal-approve__text-container {
	display: flex;
	flex-direction: column;
	gap: 7px;
	text-align: center;
}

.modal-approve__title {
	font-size: 29px;
	font-weight: 600;
	line-height: 32px;
	color: #000000;
	margin: 0;
	padding: 0;
}

.modal-approve__title_span {
	color: #e6575d;
}

.modal-approve__title_dark {
	color: #fff;
}

.modal-approve__subtitle {
	font-size: 18px;
	font-weight: 400;
	line-height: 22px;
	color: #000000;
	margin: 0;
	padding: 0;
}

.modal-approve__subtitle_dark {
	color: #fff;
}

.modal-approve__image {
	width: 211px;
	height: 211px;
	margin: 20px 0 9px 0;
}

.modal-approve__buttons-container {
	display: flex;
	flex-direction: column;
	gap: 11px;
	width: 100%;
	max-width: 424px;
}

.modal-approve__first-button {
	background-color: #0b2a61;
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	margin: 0;
	font-size: 18px;
	font-weight: 500;
	line-height: 18px;
	color: #ffffff;
	border-radius: 14px;
	text-decoration: unset;
	cursor: pointer;
	height: 51px;
	transition: all 0.2s;
}

.modal-approve__first-button:hover {
	opacity: 0.7;
}

.modal-approve__first-button_dark {
	background: linear-gradient(90deg, #4062CF, #6521DA)
}

.modal-approve__second-button {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	color: #0B2A61;
	background-color: #FDFDFD;
	border-radius: 14px;
	font-size: 18px;
	font-weight: 500;
	line-height: 18px;
	cursor: pointer;
	margin: 0;
	box-sizing: border-box;
	height: 51px;
	border: unset;
	transition: all 0.2s;
}

.modal-approve__second-button:hover {
	background-color: #E5E6F4;
}

.modal-approve__second-button_dark {
	background-color: #343434;
	color: #fff;
}

.modal-approve__second-button_dark:hover {
	background-color: #4A4A4A;
}

@media screen and (max-width: 768px) {
	.modal-approve {
		padding: 24px 32px;
	}

	.modal-approve__title {
		font-size: 24px;
		line-height: 26px;
	}

	.modal-approve__subtitle {
		font-size: 15px;
		line-height: 18px;
	}

	.modal-approve__image {
		width: 142px;
		height: 142px;
		margin: 9px 0 0 0;
	}

	.modal-approve__buttons-container {
		max-width: 326px;
	}

	.modal-approve__first-button {
		height: 40px;
		font-size: 16px;
		line-height: 16px;
	}

	.modal-approve__second-button {
		height: 40px;
		font-size: 16px;
		line-height: 16px;
	}
}
</style>
