<template>
	<AppModals
		name="confirm-order"
		title="Оформление заказа"
		cancelButtonText="Закрыть"
		confirmButtonText="Подтверждаю заказ"
		@confirm="submitForm"
		@beforeOpen="beforeOpen"
		:loadingConfirm="loading"
		:disabledConfirm="submit || !$v.form.$anyDirty"
		:clickToClose="false"
	>
		<template #content="{}">
			<div class="modal-form">
				<div class="mb-5">
					<div class="mb-2">
						<AppSelect
							placeholder="Выберите адрес"
							label="Адрес"
							valueSelect="id"
							:options="addressOptions"
							:error="$v.form.address.$error ? 'Выберите адрес' : ''"
							v-model="$v.form.address.$model"
							textSelect="address"
						/>
					</div>

					<div class="mb-2">
						<AppInput
							placeholder="Введите телефон"
							type="text"
							v-model.trim="$v.form.phone.$model"
							:error="$v.form.phone.$error"
							required
							:requiredError="$v.form.phone.$required"
							label="Телефон"
						>
							<template slot="required"
								>Введите корректный номер телефона</template
							>
						</AppInput>
					</div>

					<AppTextarea
						placeholder="Комментарий"
						type="text"
						v-model.trim="form.description"
						label="Комментарий"
						cols="45"
						rows="6"
					>
					</AppTextarea>
				</div>
			</div>
		</template>
	</AppModals>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapGetters, mapMutations } from "vuex";

let testPhone = val => {
	let test = val ? /^((\+?7)|(8))\d{10}$/.test(val) : true;
	return test;
};

export default {
	data() {
		return {
			form: {
				address: {},
				description: "",
				phone: ""
			},
			loading: false,
			submit: false,
			apartmentId: "noApartment"
		};
	},

	props: {},
	mounted() {
		this.loadInfo();
	},
	computed: {
		...mapGetters(["getUserRequestAddress", "getCart", "getActiveTownId"]),
		addressOptions() {
			if (this.apartmentId !== "noApartment") {
				return this.getUserRequestAddress.data.filter(
					item => item.apartmentId == this.apartmentId
				);
			}
			return this.getUserRequestAddress.data;
		}
	},
	methods: {
		...mapMutations(["removeCartGroup"]),
		beforeOpen({ apartmentId }) {
			this.apartmentId = apartmentId;
			if (this.addressOptions.length === 1) {
				this.form.address = this.addressOptions[0]
			}
		},
		async loadInfo() {
			try {
				await this.$store.dispatch("userRequestInfo");
			} catch (e) {
				this.$errorsMod(e.response);
			}
		},

		async submitForm() {
			this.$v.form.$touch();
			if (!this.$v.form.$invalid) {
				try {
					let tempBasketItem = this.getCart[this.apartmentId].map(
						(item, index) => {
							return {
								productId: item.id,
								count: item.count,
								suplierId: item.suplierId
							};
						}
					);

					const accountsIds = []
					const selectedAddress = this.getUserRequestAddress.data.filter((item) => item.apartmentId === this.form.address.apartmentId)
					selectedAddress[0].accounts.forEach((item) => {
						accountsIds.push(item.accountId)
					})

					let formData = {
						description: this.form.description,
						phone: `+7${this.form.phone.slice(this.form.phone.length - 10)}`,
						townId:
							this.apartmentId === "noApartment" ? this.getActiveTownId : null,
						address: this.form.address.address,
						items: tempBasketItem,
						accounts: accountsIds,
					};
					let response = await this.$store.dispatch("basketOrder", formData);

					this.$modal.hide("confirm-order");
					this.$modal.show("partner-success-order", {
						message: response.message
					});
					this.removeCartGroup({ apartmentId: this.apartmentId });
				} catch (e) {
					this.$errorsMod(e.response);
				}
			}
		}
	},
	validations(val) {
		return {
			form: {
				address: {
					required
				},
				phone: {
					required,
					testPhone
				}
			}
		};
	}
};
</script>
