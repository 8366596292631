<template>
	<AppGlobalLayout
		:loading="loadingPage"
		:error="error"
		@reload="getProfileInfo"
	>
		<div slot="body" style="max-width: 1110px; margin-top: 20px">
			<RemoveUserModal />
			<AppCard class="card-profile">
				<template #body>
					<div class="profile-wrapper">
						<div class="adaptive-profile-wrapper">
							<div
								class="notifications-block-title"
								:style="getTheme === 'dark-theme' && { color: '#FEFDFF' }"
							>
								Данные профиля
							</div>
							<div class="profile-block mb-4">
								<div class="profile-block__input">
									<AppInput
										:disabled="isEmailSend"
										id="email"
										placeholder="email"
										type="text"
										:value="formEmail.email"
										@input="formEmail.email = $event.toLowerCase().trim()"
										label="Электронная почта"
									>
										<AppIcon
											slot="prepend"
											:name="formEmail.isConfirmed ? 'check' : 'exclamation'"
											size="0.8rem"
											:class="
												formEmail.isConfirmed
													? 'success-color'
													: 'warning-color'
											"
										/>
									</AppInput>
								</div>

								<div
									v-if="!formEmail.confirmed && testMail"
									class="warning-color phone-confirm"
									@click="emailConfirm()"
								>
									Подтвердить почту
								</div>

								<div class="mt-1 mb-3" v-show="formEmail.hide">
									<div class="profile-block__text">
										На указанную почту придет письмо сообщение с кодом. Введите
										его в поле ниже:
									</div>
									<div class="phone-hidden__wrap profile-input mt-1">
										<AppInput
											id="code"
											placeholder="Код"
											type="text"
											v-model.trim="formEmail.code"
											label="Код"
										>
										</AppInput>
										<AppBtn
											@click.native="changeLoginEmail()"
											styleCSS="margin-top: 5px"
										>
											Подтвердить
										</AppBtn>
									</div>
								</div>
							</div>

							<div class="profile-block">
								<div class="profile-block__input">
									<AppInput
										id="phone"
										placeholder="+7 "
										type="text"
										v-mask="'+7 (###) ###-##-##'"
										v-model="formPhone.phone"
										label="Номер телефона"
									>
										<AppIcon
											slot="prepend"
											:name="formPhone.confirmed ? 'check' : 'exclamation'"
											size="0.8rem"
											:class="
												formPhone.confirmed ? 'success-color' : 'warning-color'
											"
										/>
									</AppInput>
								</div>

								<AppTimer
									ref="phoneTimerRef"
									:isPhoneConfirmed="isPhoneConfirmed"
									timerName="phoneTimer"
									:confirmedValue="formPhone.phone"
									@sendPhone="sendPhone"
									@sendConfirmCode="confirmPhone"
								/>
							</div>

							<div class="profile-block mt-4 mb-4">
								<div class="profile-block__password">
									<div class="profile-block__input">
										<AppInput
											:placeholder="
												formPassword.hide
													? 'Введите текущий пароль'
													: '********'
											"
											id="password"
											type="password"
											v-model="$v.formPassword.oldPassword.$model"
											label="Пароль"
											:disabled="!formPassword.hide"
										>
										</AppInput>
									</div>
									<div
										class="profile-block__click"
										@click="formPassword.hide = !formPassword.hide"
										:style="
											getTheme === 'dark-theme'
												? { color: '#cdcdd6', whiteSpace: 'nowrap' }
												: { whiteSpace: 'nowrap' }
										"
									>
										Изменить пароль
									</div>
								</div>

								<div v-if="formPassword.hide" style="max-width: 310px">
									<div class="profile-block mt-2">
										<AppInput
											id="newPassword"
											placeholder="Введите новый пароль"
											type="password"
											v-model="$v.formPassword.newPassword.$model"
											label="Новый пароль"
										/>
									</div>
									<div class="profile-block mt-2">
										<AppInput
											id="confirmPassword"
											placeholder="Повторите новый пароль"
											type="password"
											v-model="$v.formPassword.repeatPassword.$model"
											label="Повторите новый пароль"
										/>
									</div>

									<br />
									<br />
									<div class="mb-1" style="color: #bababa">
										Пароль должен содержать не менее 8 знаков, включать
										латинские буквы, цифры и спецсимволы.
									</div>
									<div
										class="danger-color"
										v-show="
											formPassword.newPassword !== formPassword.repeatPassword
										"
									>
										Пароли не совпадают
									</div>
									<div class="danger-color" v-show="formPassword.error">
										{{ formPassword.error }}
									</div>

									<div class="profile-block mt-2">
										<AppBtn
											@click.native="formPassword.hide = !formPassword.hide"
											styleCSS="margin-right: 5px"
											outlined
										>
											Отменить
										</AppBtn>
										<AppBtn
											@click.native="changePassword"
											:loading="loading"
											color="primary"
										>
											Изменить
										</AppBtn>
									</div>
								</div>
							</div>
							<AppBtn
								color="danger"
								large
								@click="
									$modal.show('delete-user', { userId: getProfileInfoGetters })
								"
							>
								Удалить профиль
							</AppBtn>

							<snackbar ref="snackbar" />
						</div>
					</div>
				</template>
			</AppCard>
		</div>
	</AppGlobalLayout>
</template>

<script>
import { required, sameAs, minLength } from "vuelidate/lib/validators";
import { mapGetters, mapMutations } from "vuex";
import ToggleButton from "./toggle-button-component.vue";
import RemoveUserModal from "./remove-user-modal.vue";
import AppTimer from "./components/AppTimer.vue";
import { times } from "lodash";

let testPassword = val => {
	let test = val ? /\d/.test(val) : true;
	return test;
};

export default {
	data() {
		return {
			isEmailSend: false,
			formEmail: {
				oldEmail: "",
				email: "",
				code: "",
				hide: false,
				isConfirmed: false,
				timer: undefined,
			},
			formPhone: {
				oldPhone: "",
				phone: "",
				confirmed: false,
				code: "",
				hide: false,
				timer: undefined,
			},
			// picked: 1,
			formPassword: {
				oldPassword: "",
				newPassword: "",
				repeatPassword: "",
				hide: false,
				error: ""
			},
			timer: 60,
			loading: false,
			loadingPage: false,
			error: false
		};
	},
	mounted() {
		this.getProfileInfo();
	},
	computed: {
		testPhone() {
			let test = /(\+7|8)[- _]*\(?[- _]*(\d{3}[- _]*\)?([- _]*\d){7}|\d\d[- _]*\d\d[- _]*\)?([- _]*\d){6})/g.test(
				this.formPhone.phone
			);
			return test;
		},
		testMail() {
			let test = /^[\w\-+]+([.][\w\-+]+)*@(?:[А-Яа-яЁёA-Za-z0-9]+(-([А-Яа-яЁёA-Za-z0-9])+)*\.)+[А-Яа-яЁёA-Za-z]{2,}$/.test(
				this.formEmail.email
			);
			return test;
		},
		isPhoneConfirmed() {
			return !this.formPhone.confirmed && this.testPhone;
		},
		...mapGetters(["getErrorsProfile", "getTheme", "getProfileInfoGetters"])
	},
	methods: {
		...mapMutations(["setErrorsProfile"]),
		async getProfileInfo() {
			this.loadingPage = true;
			this.error = false;
			try {
				let query = await this.$store.dispatch("getProfileInfo");
				this.formEmail.oldEmail = query.email;
				this.formEmail.email = query.email;
				this.formEmail.confirmed = query.isEmailConfirmed;
				this.formEmail.isConfirmed = query.isEmailConfirmed;

				this.formPhone.oldPhone = query.phone;
				this.formPhone.phone = query.phone;
				this.formPhone.confirmed = query.isPhoneConfirmed;
			} catch (e) {
				this.setErrorsProfile(e.message);

				if (e.response) {
					this.$errorsMod(e.response);
				} else {
					this.error = true;
					this.$errorsMod(e);
				}
			}
			this.loadingPage = false;
		},

		async emailConfirm() {
			if (
				!this.formEmail.timer || Date.now() - this.formEmail.timer > 60000
			) {
				this.setTimer("emailTimer");
				try {
					this.isEmailSend = true;
					this.formEmail.oldEmail = this.formEmail.email;
					await this.$store.dispatch("sendConfirmLogin", this.formEmail.email);
					this.formEmail.hide = true;
				} catch (e) {
					this.$errorsMod(e.response);
				}
			}
		},

		phoneConfirmReset() {
			this.timer = 60;
			this.phoneConfirm();
		},
		async sendPhone(sendingPhone) {
			let phone = parseInt(sendingPhone.replace(/\D+/g, ""));
			try {
				await this.$store.dispatch("sendConfirmLogin", phone);
			} catch (e) {
				this.$errorsMod(e.response);
			}
		},
		async phoneConfirm() {
			if (
				!this.formPhone.timer || Date.now() - this.formPhone.timer > 60000
			) {
				this.setTimer("phoneTimer");
				try {
					let phone = parseInt(this.formPhone.phone.replace(/\D+/g, ""));

					let inetval;

					if (this.timer == 60) {
						await this.$store.dispatch("sendConfirmLogin", phone);
						this.formPhone.hide = true;
						inetval = setInterval(() => {
							if (this.timer > 0) {
								this.timer--;
							} else {
								clearInterval(inetval);
							}
						}, 1000);
					} else if (this.timer == 0) {
						clearInterval(inetval);
						this.phoneConfirmReset();
					} else {
						this.$refs.snackbar.showSnackbar(
							`Должно пройти ${this.timer} сек.`,
							"error"
						);
					}
				} catch (e) {
					this.$errorsMod(e.response);
				}
			}
		},

		async changeLoginEmail() {
			try {
				await this.$store.dispatch("changeLogin", {
					login: this.formEmail.email,
					code: this.formEmail.code
				});
				this.formEmail.confirmed = true;
				this.formEmail.hide = false;

				this.$refs.snackbar.showSnackbar("Email успешно изменён", "success");
			} catch (e) {
				this.$errorsMod(e.response);
			} finally {
				this.isEmailSend = false;
			}
		},
		async confirmPhone(data) {
			try {
				await this.$store.dispatch("changeLogin", data);
				await this.getProfileInfo();
				this.$refs.phoneTimerRef.clearTimer();
			} catch (e) {
				this.$errorsMod(e.response);
			}
		},
		async confirmPhoneNumber() {
			let phone = parseInt(this.formPhone.phone.replace(/\D+/g, ""));
			try {
				await this.$store.dispatch("changeLogin", {
					login: phone,
					code: this.formPhone.code
				});
				this.formPhone.confirmed = true;
				this.formPhone.hide = false;
				this.formPhone.oldPhone =
					"+" + parseInt(this.formPhone.phone.replace(/\D+/g, "")); // трубруется так как v-mask подставляет масску телефона
				this.formPhone.code = "";
				this.$refs.snackbar.showSnackbar("Телефон успешно изменён", "success");
			} catch (e) {
				this.$errorsMod(e.response);
			}
		},

		async changePassword() {
			this.$v.formPassword.$touch();
			this.formPassword.error = "";

			if (!this.$v.formPassword.$invalid) {
				this.loading = true;
				try {
					await this.$store.dispatch("changePassword", {
						oldPassword: this.formPassword.oldPassword,
						newPassword: this.formPassword.newPassword
					});
					this.$refs.snackbar.showSnackbar("Пароль успешно изменён", "success");
				} catch (e) {
					this.$errorsMod(e.response);
				}
				this.loading = false;
			} else {
				this.formPassword.error = "";
				if (!this.$v.formPassword.oldPassword.required) {
					this.formPassword.error = "Ввведите старый пароль";
				} else if (
					!this.$v.formPassword.newPassword.minLength ||
					!this.$v.formPassword.repeatPassword.minLength
				) {
					this.formPassword.error = "Минимальная длина пароля 8 символов";
				} else if (
					!this.$v.formPassword.newPassword.required ||
					!this.$v.formPassword.repeatPassword.required
				) {
					this.formPassword.error = "Заполните все поля";
				} else if (
					!this.$v.formPassword.newPassword.testPassword ||
					!this.$v.formPassword.repeatPassword.testPassword
				) {
					this.formPassword.error = "Пароль должен содержать хотя бы 1 цифру";
				} else if (!this.$v.formPassword.repeatPassword.sameAsPassword) {
					this.formPassword.error = "Пароли не совпадают";
				}
			}
		},

		async isLoginInUse(login, type) {
			if (type == "email") {
				if (this.formEmail.oldEmail !== this.formEmail.email) {
					this.formEmail.confirmed = false;
				} else {
					this.formEmail.confirmed = true;
				}
			} else if (type == "phone" && this.formPhone.phone) {
				let phone = "+" + parseInt(this.formPhone.phone.replace(/\D+/g, "")); // трубруется так как v-mask подставляет масску телефона

				if (this.formPhone.oldPhone !== phone) {
					this.formPhone.confirmed = false;
				} else {
					this.formPhone.confirmed = true;
				}
			}
		},
		setTimer(name) {
			const time = Date.now();
			if (name === 'phoneTimer') {
				this.formEmail.timer = time
			} else if (name === 'emailTimer') {
				this.formPhone.timer = time
			}
		}
	},
	components: {
		ToggleButton,
		RemoveUserModal,
		AppTimer
	},
	watch: {
		"formEmail.email"(val) {
			this.isLoginInUse(val, "email");
		},
		"formPhone.phone"(val) {
			this.isLoginInUse(val, "phone");
		}
	},
	validations: {
		formPassword: {
			oldPassword: {
				required
			},
			newPassword: {
				required,
				minLength: minLength(8),
				testPassword
			},
			repeatPassword: {
				required,
				sameAsPassword: sameAs("newPassword"),
				minLength: minLength(8),
				testPassword
			}
		}
	}
};
</script>
<style scope lang="scss">
.profile-wrapper {
	display: flex;

	@media only screen and (max-width: 1035px - 1) {
		flex-direction: column;
		align-items: inherit;
	}
}

.adaptive-profile-wrapper {
	min-width: 450px;

	@media only screen and (max-width: 1035px - 1) {
		min-width: 0px;
	}
}

.notifications-block {
	border-left: 1px solid #a5a5a4;
	margin-left: 20px;
	max-width: 600px;
	padding-left: 20px;

	@media only screen and (max-width: 1035px - 1) {
		border-top: 1px solid #a5a5a4;
		border-left: none;
		margin-top: 20px;
		padding-left: 0px;
		padding-top: 10px;
	}
}

.notifications-block-title {
	font-weight: 500;
	color: #171715;
	font-size: 22px;
	margin-bottom: 20px;
}

.notifications-block-description {
	color: #777776;
	font-size: 16px;
	margin-bottom: 20px;
}

.notifications-block-check-box {
	max-width: 350px;
	color: #171715;
	font-weight: 400;
	font-size: 18px;
	display: flex;
	justify-content: space-between;
}

.toggle-wrapper {
	@media only screen and (max-width: 768px - 1) {
		margin-top: 5px;
	}
}
</style>
