<template>
	<div>
		<div v-if="isPhoneConfirmed && seconds <= 0">
			<div class="warning-color phone-confirm mb-2" @click="sendMessage">
				Подтвердить номер телефона
			</div>
		</div>
		<div v-if="timer.sendedNumber">
			<div class="mt-2">
				На номер {{ timer.sendedNumber }} придет СМС-сообщение <br />
				с одноразовым кодом. Введите его в поле ниже:
			</div>
		</div>
		<div v-if="timer.sendedNumber">
			<div>
				<AppInput
					v-model="code"
					placeholder="Код"
					type="text"
					label="Код"
					class="mt-2"
					style="max-width: 310px"
				/>
				<AppBtn
					:disabled="!code"
					@click="sendConfirmCode"
					styleCSS="margin-top: .5em"
				>
					Подтвердить
				</AppBtn>
			</div>
			<div class="mt-2">
				Если вы не получили код, подождите окончание таймера и повторите
				отправку кода
			</div>
			<div v-if="seconds > 0" class="mt-1">
				Повторная отправка будет доступна через {{ seconds }} секунд
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: ["timerName", "confirmedValue", "isPhoneConfirmed"],
	data() {
		return {
			timer: {
				timerId: null,
				lastSendDate: null,
				sendedNumber: null
			},
			seconds: null,
			code: ""
		};
	},
	computed: {},
	methods: {
		startTimer(seconds) {
			clearInterval(this.timer.timerId);
			this.seconds = seconds;
			this.timer.timerId = setInterval(() => {
				if (this.seconds > -120) {
					this.seconds--;
				} else {
					clearInterval(this.timer.timerId);
					this.timer = {
						timerId: null,
						lastSendDate: null,
						sendedNumber: null
					};
				}
			}, 1000);
		},
		sendMessage() {
			const remainingTime = Math.ceil(
				(Date.now() - this.timer.lastSendDate) / 1000
			);
			if (0 < remainingTime && remainingTime < 60) {
				console.log("was sended");
			} else {
				this.$emit("sendPhone", this.confirmedValue);
				this.timer = {
					...this.timer,
					lastSendDate: Date.now(),
					sendedNumber: this.confirmedValue
				};
				this.startTimer(60);
			}
		},
		sendConfirmCode() {
			this.$emit("sendConfirmCode", {
				login: parseInt(this.timer.sendedNumber.replace(/\D+/g, "")),
				code: this.code
			});
		},
		clearTimer() {
			clearInterval(this.timer.timerId);
			this.timer = {
				timerId: null,
				lastSendDate: null,
				sendedNumber: null
			};
		}
	},
	mounted() {
		if (this.timer.lastSendDate) {
			const remainingTime = Math.ceil(
				(Date.now() - this.timer.lastSendDate) / 1000
			);
			if (0 < remainingTime && remainingTime < 180) {
				this.startTimer(60 - remainingTime);
			}
		}
	},
	beforeDestroy() {
		clearInterval(this.timer.timerId);
	}
};
</script>
